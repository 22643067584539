<template>
  <section class="content">
    <div class="form-control bos-alert-danger lh-1 d-flex align-items-center h-100 w-fit mb-3" v-if="info.dana_tertahan">
      <div class="row">
        <div class="col-auto d-flex align-items-center">
          <i class="fi fi-rr-info d-flex align-items-center"></i>
        </div>
        <div class="col-auto d-flex align-items-center">
          <div class="text-md">
            Saldo Tertahan:
          </div>
        </div>
        <div class="col d-flex align-items-center">
          <div class="text-md font-weight-bold text-danger">
            Rp {{ formatMoney(info.ongkir_dasar) }}
        </div>
        </div>
      </div>
    </div>
    <div class="card card-primary">
      <!-- form start -->
      <form role="form" id="form_user" class="form-summary">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="exampleInputEmail1" class="col-6">Kode Order</label>
                <span class="col-6">{{ info.order_code }}</span>
              </div>
              <div class="form-group row">
                <label class="col-6">No Resi</label>
                <span class="col-6"
                  ><h4>
                    {{ info.no_resi }}
                    <button ref="btnCopy" class="btn btn-sm btn-default" data-toggle="tooltip" data-placement="top" title="Copy to clipboard" type="button" @click="copyCode"
                      ><i class="fa fa-copy"></i
                    ></button></h4
                ></span>
              </div>
              <div class="form-group row">
                <label class="col-6">Service</label>
                <span class="col-6"><img height="25" :src="getKurirLogo(info.courierCode)" />{{info.courier_service_code}}</span>
              </div>
              <div class="form-group row">
                <label class="col-6">Tanggal Order</label>
                <span class="col-6">{{ !info.ordered_on ? '-' : formatDate(info.ordered_on) }}</span>
              </div>
              <div class="form-group row">
                <label class="col-6">Tanggal Pickup</label>
                <span class="col-6">{{ formatDate(info.pickup_time) }}</span>
              </div>
              <div class="form-group row">
                <label class="col-6">Status</label>
                <span class="col-6"
                  ><span :class="'badge mr-1 mb-1 badge-' + info.statusType"
                    >{{ info.statusMsg }}
                  </span>
				  <span :class="'badge mr-1 mb-1 bg-indigo text-light'" v-if="info.order_status == 2">Void</span>
				  <span :class="'badge mr-1 mb-1 border-' + (info.special_claim == 1 ? 'info text-info' : info.special_claim == 2 ? 'success text-success' : info.special_claim == 3 ? 'danger text-danger' : 'warning text-warning')" style="border-width: thin;border-style: solid;" v-if="info.special_claim > 0">{{ info.special_category }}</span>
                  <!-- <a href="#" v-if="info.pickup_status==1" class="ml-2 lacak" v-on:click="lacakResi">
                    Lacak kiriman</a
                  > -->
                  <div v-if="info.error_message">{{info.error_message}}</div>
                  </span>
                
              </div>
              <h3 class="mt-3">Info Paket</h3>
              <!-- <div class="form-group row">
                <label class="col-6">Shipper</label>
                <span class="col-6">{{ info.display_name }}</span>
              </div> -->
              <div class="form-group row">
                <label class="col-6">Isi Paket</label>
                <span class="col-6">{{ info.item_name }}</span>
              </div>
              <div class="form-group row">
                <label class="col-6">Tipe Pengiriman</label>
                <span class="col-6">{{ info.status_cod == 1 ? 'COD' : 'Non-COD' }}</span>
              </div>
              <div class="form-group row" v-if="statusWeightChange == true">
                <label class="col-6" style="color: green;">Berat Baru</label>
                <span class="col-6" style="color: green;">{{ weightRevision }} Kg</span>
                <label class="col-6" style="color: red;">Berat Lama</label>
                <span class="col-6" style="color: red;">{{ weightRevisionFirst }} Kg</span>
              </div>
              <div class="form-group row" v-else>
                <label class="col-6">Berat</label>
                <span class="col-6">{{ info.item_weight }} Kg</span>
              </div>
              <div class="form-group row">
                <label class="col-6">Volume</label>
                <span class="col-6">P ({{ info.item_length }}cm) x L ({{info.item_width}}cm) x T ({{info.item_height}}cm)</span>
              </div>
              <div class="form-group row">
                <label class="col-6 mr-2">Nilai Paket</label>
                <div class="row">
                    <div class="col-xs-4">
                    <span class="col-6"><span>Rp {{ formatMoney(info.item_value) }}</span></span>
                    </div>
                </div>
              </div> 
              <div v-if="info.status_cod == 1" class="form-group row">
                <label class="col-6 mr-2">Nilai COD</label>
                <div class="row">
                    <div class="col-xs-4">
                    <span class="col-6"><span>Rp {{ formatMoney(info.nilai_cod) }}</span></span>
                    </div>
                </div>
              </div> 
				<div class="form-group row" v-if="countOrderRevision > 1">
					<label class="col-6 mr-2" style="color: green;">Nilai Ongkir Baru</label>
					<div class="row">
						<div class="col-xs-4">
						<span class="col-6" style="color: green;"><span>Rp {{ formatMoney(shippingFeeDiscountLast) }}</span></span>
						</div>
						<!-- <div>
							<VTooltip theme="boscod">
								<button type="button" class="btninfo"><i class="fas fa-info-circle"></i></button>

								<template #popper>
									<table class="table table-borderless" style="font-size: 13px;width: 100%;">
								<tbody>
									<tr v-if="info.status_cod == 1">
										<td class="text-left">Nilai COD</td>
										<td>Rp</td>
										<td class="text-right">{{formatMoney(info.nilai_cod)}}</td>
									</tr>
									<tr>
										<td class="text-left">Ongkir + PPN</td>
										<td>Rp</td>
										<td class="text-right">{{formatMoney(shippingFeeDiscountLast)}}</td>
									</tr>
									<tr v-if="info.status_cod == 1">
										<td class="text-left">COD Fee + PPN</td>
										<td>Rp</td>
										<td class="text-right">{{formatMoney(info.feecod_shipper+info.ppncod_shipper)}}</td>
									</tr>
									<tr>
										<td class="text-left">Asuransi</td><td>Rp</td>
										<td class="text-right">{{formatMoney(info.insurance_fee)}}</td>
									</tr>
									<tr style="border-top-style: solid; border-width: 2px;">
										<td class="text-left">{{ info.status_cod == 1 ? 'Diterima' : 'Total Bayar' }}</td>
										<td>Rp</td>
										<td class="text-right">{{info.status_cod == 1 ? formatMoney(newGrandTotal) : formatMoney(-1*newGrandTotal) }}</td>
									</tr>
								</tbody>
								</table>
								</template>
							</VTooltip>
						</div> -->
					</div>
					<label class="col-6 mr-2" style="color: red;">Nilai Ongkir Lama</label>
					<div class="row">
						<div class="col-xs-4">
						<span class="col-6" style="color: red;"><span>Rp {{ formatMoney(shippingFeeDiscountFirst) }}</span></span>
						</div>
						<!-- <div>
							<VTooltip theme="boscod">
								<button type="button" class="btninfo"><i class="fas fa-info-circle"></i></button>

								<template #popper>
									<table class="table table-borderless" style="font-size: 13px;width: 100%;">
								<tbody>
									<tr v-if="info.status_cod == 1">
										<td class="text-left">Nilai COD</td>
										<td>Rp</td>
										<td class="text-right">{{formatMoney(info.nilai_cod)}}</td>
									</tr>
									<tr>
										<td class="text-left">Ongkir + PPN</td>
										<td>Rp</td>
										<td class="text-right">{{formatMoney(shippingFeeDiscountFirst)}}</td>
									</tr>
									<tr v-if="info.status_cod == 1">
										<td class="text-left">COD Fee + PPN</td>
										<td>Rp</td>
										<td class="text-right">{{formatMoney(info.feecod_shipper+info.ppncod_shipper)}}</td>
									</tr>
									<tr>
										<td class="text-left">Asuransi</td><td>Rp</td>
										<td class="text-right">{{formatMoney(info.insurance_fee)}}</td>
									</tr>
									<tr style="border-top-style: solid; border-width: 2px;">
										<td class="text-left">{{ info.status_cod == 1 ? 'Diterima' : 'Total Bayar' }}</td>
										<td>Rp</td>
										<td class="text-right">{{info.status_cod == 1 ? formatMoney(newGrandTotalFirst) : formatMoney(-1*newGrandTotalFirst) }}</td>
									</tr>
								</tbody>
								</table>
								</template>
							</VTooltip>
						</div> -->
					</div>
				</div> 
				<div class="form-group row" v-else>
					<label class="col-6 mr-2">Nilai Ongkir</label>
					<div class="row">
						<div class="col-xs-4">
						<span class="col-6"><span>Rp {{ formatMoney(info.nilai_ongkir) }}</span></span>
						</div>
						<!-- <div>
							<VTooltip theme="boscod">
								<button type="button" class="btninfo"><i class="fas fa-info-circle"></i></button>

								<template #popper>
									<table class="table table-borderless" style="font-size: 13px;width: 100%;">
								<tbody>
									<tr v-if="info.status_cod == 1">
										<td class="text-left">Nilai COD</td>
										<td>Rp</td>
										<td class="text-right">{{formatMoney(info.nilai_cod)}}</td>
									</tr>
									<tr>
										<td class="text-left">Ongkir + PPN</td>
										<td>Rp</td>
										<td class="text-right">{{formatMoney(info.nilai_ongkir)}}</td>
									</tr>
									<tr v-if="info.status_cod == 1">
										<td class="text-left">COD Fee + PPN</td>
										<td>Rp</td>
										<td class="text-right">{{formatMoney(info.feecod_shipper+info.ppncod_shipper)}}</td>
									</tr>
									<tr>
										<td class="text-left">Asuransi</td><td>Rp</td>
										<td class="text-right">{{formatMoney(info.insurance_fee)}}</td>
									</tr>
									<tr style="border-top-style: solid; border-width: 2px;">
										<td class="text-left">{{ info.status_cod == 1 ? 'Diterima' : 'Total Bayar' }}</td>
										<td>Rp</td>
										<td class="text-right">{{info.status_cod == 1 ? formatMoney(info.grand_total) : formatMoney(-1*info.grand_total) }}</td>
									</tr>
								</tbody>
								</table>
								</template>
							</VTooltip>
						</div> -->
					</div>
				</div> 
              <div class="form-group row" v-if="info.deleted == 1 && roleid == 2">
                <label class="col-6">Alasan Pembatalan</label>
                <span class="col-6">{{ info.delete_reason ? info.delete_reason : 'Paket dibatalkan' }}</span>
              </div>
              <div class="callout callout-info" v-if="info.ext_field1">
                <h5><i class="icon fas fa-info"></i> Catatan Lain</h5>
                <p>{{info.ext_field1}}</p>
              </div>
            </div>
            <div class="col-md-6">
              <h3>Rincian Pembayaran</h3>
              <div class="form-group row">
                <label class="col-6">Berat Paket {{info.calculated_weight != null && info.item_weight !== info.calculated_weight ? '(Volumetrik)' : ''}}</label>
                <span class="col-6">{{info.calculated_weight != null && info.item_weight !== info.calculated_weight ? info.calculated_weight : info.item_weight}} Kg</span>
              </div>
              <div class="form-group row">
                <label class="col-6">Nilai Paket</label>
                <span class="col-6">Rp {{ formatMoney(info.item_value) }}</span>
              </div>
              <div class="form-group row">
                <label class="col-6" :class="{ 'cursor-pointer' : info.status_cod == 1 }" @click="toggleDetail">Ongkir <span v-if="info.status_cod == 1">dan COD Fee</span> (Termasuk PPN) <i class="fi" :class="( showDetail ? 'fi-rr-angle-small-up' : 'fi-rr-angle-small-down' )" v-if="info.status_cod == 1"></i></label>
                <span class="col-6">Rp {{ formatMoney(parseFloat(info.nilai_ongkir) + parseFloat(info.feecod_shipper) + parseFloat(info.ppncod_shipper)) }}</span>
              </div>

              <div class="form-group row" style="opacity: .5" v-if="info.status_cod == 1 && showDetail">
                <label class="col-6">Ongkir + PPN</label>
                <span class="col-6">Rp {{ formatMoney(info.nilai_ongkir) }}</span>
              </div>
              <div class="form-group row" style="opacity: .5" v-if="info.status_cod == 1 && showDetail">
                <label class="col-6">COD Fee + PPN</label>
                <span class="col-6">Rp {{ formatMoney(parseFloat(info.feecod_shipper) + parseFloat(info.ppncod_shipper)) }}</span>
              </div> 
              <div class="form-group row">
                <label class="col-6">Biaya Asuransi</label>
                <span class="col-6">Rp {{ formatMoney(info.insurance_fee) }}</span>
              </div> 
              <div class="form-group row">
                <label class="col-6">{{ info.status_cod == 1 ? 'Nilai yang kamu terima' : 'Total' }}</label>
                <span class="col-6">Rp {{info.status_cod == 1 ? formatMoney(info.grand_total) : formatMoney(-1*info.grand_total) }}</span>
              </div> 
              <div class="form-group row font-weight-bold" style="color: #e96936;">
                <label class="col-6">{{ info.status_cod == 1 ? 'Ditagihkan ke customer' : 'Total Pembayaran' }}</label>
                <span class="col-6">Rp {{ info.status_cod == 1 ? formatMoney(info.nilai_cod) /* parseFloat(info.item_value) + parseFloat(info.insurance_fee) + parseFloat(info.nilai_ongkir) + parseFloat(info.feecod_shipper) + parseFloat(info.ppncod_shipper) */ : formatMoney(-1*info.grand_total) }}</span>
              </div> 
            </div>
          </div>
          <br />
          <table class="table table-bordered">
            <tr>
              <th width="20%"></th>
              <th width="40%">Pengirim</th>
              <th width="40%">Penerima</th>
            </tr>
            <tr>
              <th>Nama</th>
              <td>{{ info.sender_name }}</td>
              <td>{{ info.receiver_name }}</td>
            </tr>
            <tr>
              <th rowspan="2">Alamat</th>
              <td>{{ info.sender_address }}</td>
              <td>{{ info.receiver_address }}</td>
            </tr>
            <tr>
              <td>Prov. {{ info.prov }}, Kab. {{ info.kab }}, Kec. {{ info.kec }}</td>
              <td>Prov. {{ info.receiver_propinsi }}, Kab. {{ info.receiver_kota }}, Kec. {{ info.receiver_kecamatan }}</td>
            </tr>
            <tr>
              <th>Telpon</th>
              <td>{{ info.sender_phone }}</td>
              <td>{{ info.receiver_phone }}</td>
            </tr>
          </table>
          <h3 v-if="countOrderRevision > 1" class="mt-3" style="padding-top: 10px">History Perubahan Ongkir</h3>
          <table v-if="countOrderRevision > 1" class="table table-bordered">
            <tr>
              <th>Tanggal</th>
              <th>Nilai Ongkir Dasar</th>
              <th>Nilai Ongkir + Diskon</th>
              <th>Perubahan Ongkir</th>
            </tr>
            <tr v-for="(value, index) in orderRevision">
              <td v-if="value.updated_date != null">{{ formatDate(value.updated_date) }}</td>
              <td v-if="value.updated_date != null">{{ formatMoney(value.shipping_fee) }}</td>
              <td v-if="value.updated_date != null">{{ formatMoney(value.shipping_fee_discount) }}</td>
              <td v-if="value.updated_date != null">{{ value.info }}</td>
            </tr>
          </table>
          <hr />
        </div>
        <!-- /.box-body -->
        <div class="card-footer form-actions">
          <!-- <button
            v-if="newTicket"
            type="button"
            class="btn btn-primary mr-2"
            v-on:click="createTicket"
          >
            <i class="fa fa-question-circle"></i>
            Bantuan
          </button> -->
          <!-- <button
            v-if="!newTicket"
            type="button"
            class="btn btn-primary mr-1"
            v-on:click="showTicket"
          >
            <i class="fa fa-question-circle"></i>
            Lihat Bantuan
          </button> -->
          <a
            v-if="countOrderRevision > 1"
            type="button"
            class="btn btn-primary mr-2"
            href="https://linktr.ee/boscod"
            target="_blank"
          >
            <i class="fa fa-question-circle"></i>
            Hubungi CS
        </a>
          <router-link
            :to="{ path: '/riwayat' }"
            class="btn btn-secondary d-none"
          >
            <i class="fa fa-undo"></i> Kembali
          </router-link>
          <button
            type="button"
            class="btn btn-secondary mr-2"
            v-on:click="goBack"
          >
            <i class="fas fa-window-close"></i> Tutup
          </button>
          <!-- <button
            v-if="info.statusMsg == 'Draft' || info.statusMsg == 'Error'"
            type="button"
            class="btn btn-success mr-2"
            v-on:click="showDraft"
          >
            <i class="fi fi-rr-paper-plane"></i>
            Lanjutkan
          </button>
          <button v-if="createClaim" type="button" class="btn btn-primary mr-2" v-on:click="claimReturn">
            <i class="fa fa-question-circle"></i> Claim
         </button>
          <button v-if="createSpecialClaim" type="button" class="btn btn-warning text-light mr-2" v-on:click="specialClaimReturn">
            <i class="fa fa-question-circle"></i> Claim Khusus
         </button> -->
        </div>
      </form>
    </div>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Riwayat Kiriman</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <section data-unify="Card" class="card-body">
              <div>
                <div class="text-right">
                  <button type="button" class="btn btn-default" v-if="!expand" @click="showAll">Selengkapnya</button>
                </div>
                <div class="css-h81dkj" v-for="row in history">
                  <div class="date">{{ row.time }}<br />{{ row.date }}</div>
                  <div class="line-wrapper">
                    <div class="circle"></div>
                    <div class="line"></div>
                  </div>
                  <div class="status-wrapper">
                    <div class="status">{{ row.note }}</div>
                    <div class="city">{{ row.branch }}</div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <FormClaim :show="openClaim" :status="trackingStatus" :special="createSpecialClaim" :courier_id="courierId" v-model="orderId"/>
    <!--<TicketComment
      :show="isShowTicket"
      :orderId="orderCode"
      v-on:change="commentCreated"
      v-on:hide="hideTicket"
    />-->
  </section>
  <!-- /.content -->
</template>
<script>
import Vue from "vue";
import { authFetch, auth, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Ticket from "@/dialog/Ticket";
import FormClaim from "@/dialog/FormClaim";
import TicketComment from "@/dialog/TicketComment";
import VTooltip from 'v-tooltip';
import 'v-tooltip/dist/v-tooltip.css';
import moment from "moment";

Vue.use(VTooltip, {
	themes: {
		'boscod': {
			$extend: 'tooltip',
			triggers: [
				'hover',
				'focus',
				'click',
				'touch',
			],
			placement: 'auto',
		},
	},
});

export default {
  name: "Orderdetail",
  data() {
    return {
      info: {
        id: "",
      },
      roleid:0,
      history: [],
      newTicket: false,
      createClaim: false,
      createSpecialClaim: false,
      specialClaim: false,
      openClaim: false,
      orderCode: "",
	    orderNo: "",
      openTicket: false,
      isShowTicket: false,
      expand: true,
      trackingStatus:0,
      orderId:0,
      weightRevision:0,
      weightRevisionFirst:0,
      shippingFee:0,
      shippingFeeFirst:0,
      shippingFeeDiscountLast:0,
      shippingFeeDiscountFirst:0,
      newGrandTotal:0,
      newGrandTotalFirst:0,
      orderRevision:[],
      countOrderRevision:0,
      statusWeightChange:false,
			customSwal: {},
      showDetail: false,
      courierId: 0,
    };
  },
  components: {
    Ticket,
    TicketComment,
    FormClaim,
  },
  computed: {},
  created: function () {
    var uinfo = auth.user();
    if (uinfo) {
      this.roleid = uinfo.role_id;
    }
  },
  methods: {
    copyCode: function(e){
      var self = this;
      navigator.clipboard.writeText(this.info.no_resi).then(function() {
        //$(self.$refs.btnCopy).attr('title', 'Kode sudah disalin')
        //$(self.$refs.btnCopy).tooltip('update')
        $(self.$refs.btnCopy).attr('title','Copied!').tooltip('_fixTitle').tooltip('show').attr('title','Copy to clipboard').tooltip('_fixTitle')
      }, function(err) {
        console.error(err);
      });
    },
    formatMoney(data) {
      return formatCurrency(data);
    },
    formatDate(data) {
      return moment(data).format("DD/MM/YYYY HH:mm");
    },
    showAll: function(){
      this.expand = true;
      this.loadHistory(this.orderCode);
    },
    ticketCreated: function () {
      this.newTicket = false;
    },
    commentCreated: function () {},
    createTicket: function () {
      //this.openTicket = !this.openTicket;
      this.$root.$emit("createTicket", this.orderNo);
    },
    showTicket: function () {
      //this.isShowTicket = !this.isShowTicket;
      this.$root.$emit("openTicketComment", this.orderNo);
    },
    claimReturn: function () {
        //this.openClaim = true;
        this.specialClaim = false;
        this.openClaim = !this.openClaim;
    },
    specialClaimReturn: function () {
      this.specialClaim = true;
      // this.specialClaim = !this.specialClaim;
      this.claimReturn();
    },
    hideTicket: function () {
      //this.isShowTicket = false;
    },
    loadInfo: function (code) {
      authFetch("/order/arsip_detail/" + code).then((res) => {
        res.json().then((json) => {
          if (json.success) {
            this.orderCode = json.data.order_code;
            if(json.data.courier_id==1)
              this.expand = false
            this.formatData(json.data);
            if (json.data.ticket_status == 0) this.newTicket = true;
            if (json.data.return_claim == 0 && json.data.return_received == 0 && json.data.tracking_status > 0 && this.roleid != 9) this.createClaim = true;
            if (json.data.tracking_status == 4 && json.data.return_received == 0 && json.data.return_claim == 3 && json.data.special_claim == 0 && json.data.special_claim_courier == 0) this.createSpecialClaim = true;
            this.courierId = json.data.courier_id;
            this.trackingStatus = json.data.tracking_status;
            this.orderId = json.data.id;
			      this.orderNo = json.data.order_no;
            if (json.order_revision.length > 1){
              this.orderRevision = json.order_revision;
              this.countOrderRevision = json.order_revision.length;

              this.weightRevision = json.order_revision[json.order_revision.length - 1].actual_weight;
              this.shippingFee = json.order_revision[json.order_revision.length - 1].shipping_fee - json.data.ins_fee;
              if (json.data.ppn_ongkir_inc == 0){
                this.shippingFeeDiscountLast = (json.order_revision[json.order_revision.length - 1].shipping_fee - json.data.insurance_fee) - (json.data.percent_shipper / 100 * (json.order_revision[json.order_revision.length - 1].shipping_fee - json.data.insurance_fee)) + (((json.order_revision[json.order_revision.length - 1].shipping_fee - json.data.insurance_fee) - (json.data.percent_shipper / 100 * (json.order_revision[json.order_revision.length - 1].shipping_fee - json.data.insurance_fee))) * json.data.ppn_ongkir / 100);
              } else {
                this.shippingFeeDiscountLast = (json.order_revision[json.order_revision.length - 1].shipping_fee - json.data.insurance_fee) - (json.data.percent_shipper / 100 * (json.order_revision[json.order_revision.length - 1].shipping_fee - json.data.insurance_fee));
              }
              this.newGrandTotal = json.data.nilai_cod - this.shippingFeeDiscountLast - (json.data.feecod_shipper + json.data.ppncod_shipper) - json.data.insurance_fee ;

              this.weightRevisionFirst = json.order_revision[0].actual_weight;
              this.shippingFeeFirst = json.order_revision[0].shipping_fee - json.data.ins_fee;
              if (json.data.ppn_ongkir_inc == 0){
                this.shippingFeeDiscountFirst = (json.order_revision[0].shipping_fee - json.data.insurance_fee) - (json.data.percent_shipper / 100 * (json.order_revision[0].shipping_fee - json.data.insurance_fee)) + ((this.shippingFeeDiscountFirst = (json.order_revision[0].shipping_fee - json.data.insurance_fee) - (json.data.percent_shipper / 100 * (json.order_revision[0].shipping_fee - json.data.insurance_fee))) * json.data.ppn_ongkir / 100);
              } else {
                this.shippingFeeDiscountFirst = (json.order_revision[0].shipping_fee - json.data.insurance_fee) - (json.data.percent_shipper / 100 * (json.order_revision[0].shipping_fee - json.data.insurance_fee));
              }
              this.newGrandTotalFirst = json.data.nilai_cod - this.shippingFeeDiscountFirst - (json.data.feecod_shipper + json.data.ppncod_shipper) - json.data.insurance_fee ;

              if (this.weightRevision != this.weightRevisionFirst){
                this.statusWeightChange = true;
              }
            }
          } else {
            this.customSwal.fire({
              title: json.desc,
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: 'Tutup',
            }).then((result) => {
              this.goBack();
            });
          }
        });
      });
    },
    formatData: function (info) {
      if(info.pickup_status==0)
      {
        if(info.status == 1 || info.job_status == 3 && info.error_message && info.tracking_status != 5){
          info.statusMsg = "Error";
          info.statusType = "danger";
        }
        else{
          if(info.job_status == 1){
            info.statusMsg = "Menunggu resi";
          } else if(info.job_status == 2){
            info.statusMsg = "Generate resi";
          } else {
            info.statusMsg = "Draft";
          }
          info.statusType = "secondary";
        }
      }
      else if (info.tracking_status == 0 && info.deleted == 1) {        
        info.statusMsg = "Cancel";
        info.statusType = "danger";
      }
      else if (info.tracking_status == 0) {  
        if(info.order_type == 1){
          info.statusMsg = "Menunggu Drop";
        } else {
          info.statusMsg = "Menunggu Pickup";
        }     
        info.statusType = "secondary";
      } else if (info.tracking_status == 1) {
        info.statusMsg = "Perjalanan";
        info.statusType = "warning";
      } else if (info.tracking_status == 2) {
        info.statusMsg = "Terkirim";
        info.statusType = "success";
      } else if (info.tracking_status == 3) {
        info.statusMsg = "Proses Retur";
        info.statusType = "warning";
      } else if (info.tracking_status == 4) {
          if (info.return_received == 0) {
              info.statusMsg = "Retur Diserahkan";
              info.statusType = "info";
          } else {
              info.statusMsg = "Retur Diterima";
              info.statusType = "danger";
          }
      }else if (info.tracking_status == 5) {
        // if (info.problem_description != null){
        //   info.statusMsg = "Paket Bermasalah - "+info.problem_description;
        // } else {
          info.statusMsg = "Paket Bermasalah";
        // }
        info.statusType = "danger";
      }
      info.feecod_shipper = parseInt(info.feecod_shipper);
      info.ppncod_shipper = parseInt(info.ppncod_shipper);
      this.info = info;
    },
    lacakResi() {
      const e = this.$refs;
      $(e.formDialog).modal("show");
    },
    toggleDetail() {
			this.showDetail = !this.showDetail;
		},
    loadHistory: function (code) {
      authFetch("/order/history/" + code+'?expand='+(this.expand?1:0)).then((res) => {
        res.json().then((json) => {
          if (json.success) {
            //this.formatData(json.data);
            this.history = json.data;
          }
        });
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    goBack() {
      console.log(window.history)
      window.close();
    },
    showDraft() {
      let route = this.$router.resolve({
        path: "/order/create/draft/" + this.orderCode
      });
      window.open(route.href,"_self");
    },
    getKurirLogo(kurir) {
			if (!kurir) return;
			const fileName = kurir.toLowerCase();
			return fileName? require(`../assets/img/${fileName}.png`) : ''; // the module request
		},
  },
  mounted() {
    // this.orderCode = this.$route.params.id;
    this.loadInfo(this.$route.params.id);
    const e = this.$refs;
    //$(e.btnCopy).tooltip()
    $(e.btnCopy).tooltip().on('mouseleave',function(){$(this).tooltip('hide')})
    $(e.formDialog).on("show.bs.modal", (e) => {
      if (this.history.length == 0) {
        /*this.history.push({
                time: '00.00',
                date: '7 Jul',
                note: 'Tiba di counter',
                branch: 'Jakarta'
            },
            {
                time: '00.00',
                date: '7 Jul',
                note: 'Tiba di counter',
                branch: 'Jakarta'
            })*/
        this.loadHistory(this.$route.params.id);
      }
    });
	this.customSwal = Swal.mixin({
		customClass: {
			popup: 'w-fit',
			title: 'h4 tex-dark',
			actions: 'd-flex justify-content-between flex-row-reverse',
			confirmButton: 'btn btn-primary w-49 font-weight-bold py-2',
			denyButton: 'btn btn-outline-primary w-49 font-weight-bold py-2',
			cancelButton: 'btn btn-primary btn-block font-weight-bold py-2',
		},
		buttonsStyling: false
	});
  },
};
</script>
<style>
.btninfo{
		margin: -3px 0 0 -8px;
		font-size:17px;
		background-color: transparent;
		border: none;
		cursor: pointer;
		overflow: hidden;
		outline: none;
}
button.btninfo:focus{
		outline: none;
}

.form-summary .form-group {
	margin-bottom: 0;
}
.form-summary .badge {
	font-size: 100%;
}
@keyframes blinker {
	50% {
		opacity: 0;
	}
}
.css-14fpj81-unf-card {
	display: block;
	position: relative;
	margin: 16px;
	padding: 24px;
	border-radius: 8px;
	box-shadow: 0 1px 6px 0 var(--color-shadow, rgba(49, 53, 59, 0.12));
	background-color: var(--color-card, #ffffff);
	cursor: default;
}

.css-h81dkj {
	width: 100%;
	vertical-align: top;
	display: flex;
	min-height: 60px;
}
.css-h81dkj .date {
	text-align: right;
	width: 18%;
	flex-shrink: 0;
	color: var(--N200, #9fa6b0);
	font-size: 0.857143rem;
	line-height: 1.5;
}
.css-h81dkj .line-wrapper {
	flex-basis: 0px;
	margin: 0px 16px;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	flex-direction: column;
	width: 3%;
	display: flex;
}
.css-h81dkj:first-child .circle {
	background-color: transparent;
	animation: blinker 1s linear infinite;
}
.css-h81dkj .circle {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	position: relative;
	margin-bottom: auto;
	background-color: var(--N100, #dbdee2);
}
.css-h81dkj .line-wrapper .line {
	width: 1px;
	height: calc(100% - 9px);
	margin-bottom: -6px;
	background-image: linear-gradient(
		to bottom,
		var(--N100, #dbdee2) 55%,
		rgba(255, 255, 255, 0) 55%
	);
	background-position: left center;
	background-size: 1px 12px;
	background-repeat: repeat-y;
}
.css-h81dkj:first-child .status-wrapper {
	padding-bottom: 36px;
}
.css-h81dkj:first-child .status-wrapper .status {
	font-weight: 800;
	line-height: 1.4;
}
.css-h81dkj .status-wrapper .status {
	color: var(--N500, #52565e);
	line-height: 1.5;
	margin-top: -0.25rem;
}
.css-h81dkj:first-child .status-wrapper .city {
	color: var(--N200, #9fa6b0);
}

.css-h81dkj .status-wrapper .city {
	color: var(--N200, #9fa6b0);
	margin-top: 8px;
}
.css-h81dkj:first-child .circle::before {
	width: 15px;
	position: absolute;
	height: 15px;
	border-radius: 50%;
	background-color: var(--G300, #f9b79d);
	transition: all 0.5s ease-out 0s;
	animation-name: grow;
	animation-duration: 1400ms;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
	animation-fill-mode: none;
	animation-play-state: running;
	content: "";
}
.css-h81dkj:first-child .circle::after {
	border-radius: 50%;
	background-color: var(--G500, #e96936);
	content: "";
	position: absolute;
	width: 11px;
	height: 11px;
	left: 2px;
	top: 2px;
}
.css-h81dkj .circle::after {
	border-radius: 50%;
	background-color: var(--N200, #9fa6b0);
	content: "";
	position: absolute;
	width: 9px;
	height: 9px;
	left: 3px;
	top: 3px;
}
.css-h81dkj:last-of-type .line {
	display: none;
}
.css-h81dkj .line-wrapper .line {
	width: 1px;
	height: calc(100% - 9px);
	margin-bottom: -6px;
	background-image: linear-gradient(
		to bottom,
		var(--N100, #dbdee2) 55%,
		rgba(255, 255, 255, 0) 55%
	);
	background-position: left center;
	background-size: 1px 12px;
	background-repeat: repeat-y;
}
</style>